/* LoginPage.css */

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh; /* ビューポートの高さに合わせる */
    background-color: rgba(67, 67, 67, 0.907);
    margin: 0;
    padding: 0;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;  /* 最大幅を設定 */
    padding: 20px;
    box-sizing: border-box;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-form label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
  }
  
  .login-form input[type="text"],
  .login-form input[type="password"],
  .login-form input[type="submit"] {
    padding: 10px;
    margin-top: 5px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .login-form input[type="submit"] {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .login-form input[type="submit"]:hover {
    background-color: #45a049;
  }
  
  /* メディアクエリを使用してスマートフォンサイズに対応 */
  @media (max-width: 600px) {
    .login-form {
      width: 90%;
    }
  
    .login-form label {
      margin-bottom: 10px;
    }
  
    .login-form input[type="text"],
    .login-form input[type="password"],
    .login-form input[type="submit"] {
      font-size: 16px;
    }
  }
  