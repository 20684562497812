.signup-content {
    font-family: Arial, sans-serif;
    background-color: rgba(67, 67, 67, 0.907);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


/* ラベルのスタイリング */
label {
    display: block;
    margin-bottom: 15px;
    color: #555;
    font-weight: bold;
}

/* 入力フィールドのスタイリング */
input[type="text"],
input[type="password"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

/* サブミットボタンのスタイリング */
input[type="submit"] {
    display: block;
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 10px 0;
    margin-top: 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

/* サブミットボタンのホバー時のスタイリング */
input[type="submit"]:hover {
    background-color: #45a049;
}
