/* Group_Select.css */

/* 全体のスタイリング */
.group-select-content {
    font-family: Arial, sans-serif;
    background-color: rgba(67, 67, 67, 0.907);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* タイトルのスタイリング */
.group-select-content .title {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

/* フォーム全体のスタイリング */
.group-select-content form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
}

/* ラベルのスタイリング */
.group-select-content label {
    display: block;
    margin-bottom: 10px;
    color: #555;
}

/* セレクトボックスのスタイリング */
.group-select-content select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

/* 入力フィールドのスタイリング */
.group-select-content input[type="file"] {
    width: calc(100% - 22px);
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

/* 送信ボタンのスタイリング */
.group-select-content .submit-btn {
    display: inline-block;
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

/* ボタンホバー時のスタイリング */
.group-select-content .submit-btn:hover {
    background-color: #45a049;
}
