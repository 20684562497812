/* Tab_System.css */
.react-tabs__tab-list {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #ccc;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #f9f9f9;
}

.react-tabs__tab {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #666;
  transition: all 0.3s ease-in-out;
}

.react-tabs__tab:hover {
  background: #b7d6e0;
  color: #000;
}

.react-tabs__tab--selected {
  border-bottom: 3px solid #007BFF;
  color: #007BFF;
  background: #fff;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 7px;
  margin: 20px 0;
}

.image-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 画像をトリミングして要素全体をカバーする */
  aspect-ratio: 1 / 1; /* 画像のアスペクト比を1:1に設定する */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .image-grid img {
    max-width: 100%;
  }
}
