/* mypage.css */

/* ボタンのコンテナスタイル */
.button-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* ボタン間のスペース */
  margin: 20px 0; /* 上下のマージン */
  flex-wrap: wrap; /* ボタンを折り返す */
}

/* ボタンのスタイル */
.mypage .makegroup,
.mypage .uppicture,
.mypage .logout {
  padding: 15px 30px; /* ボタンの大きさを調整 */
  width: auto; /* 自動幅 */
  box-sizing: border-box;
  background: linear-gradient(45deg, #6a11cb 0%, #2575fc 100%); /* 背景色を青に変更 */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px; /* 角を丸くする */
}

.mypage .makegroup:hover,
.mypage .uppicture:hover,
.mypage .logout:hover {
  background-color: #0056b3; /* ホバー時の背景色を濃い青に変更 */
}

/* タブシステムのスタイル（Tab_Systemコンポーネント用） */
.mypage .tab-system {
  width: 100%;
  max-width: 600px; /* 最大幅を設定 */
  margin: 20px auto; /* 中央に配置 */
  padding: 10px;
  box-sizing: border-box;
}

/* メディアクエリを使用してスマートフォンサイズに対応 */
@media (max-width: 600px) {
  .mypage .makegroup,
  .mypage .uppicture,
  .mypage .logout {
    width: calc(33.33% - 10px); /* ボタンを3つ横に並べるための幅 */
    font-size: 10px; /* フォントサイズを調整 */
    margin-bottom: 10px; /* 下部のマージンを追加 */
  }

  .mypage .tab-system {
    width: 100%;
    padding: 5px;
  }

  .button-container {
    justify-content: space-between; /* ボタンを等間隔に配置 */
  }
}
