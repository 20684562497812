/* 全体のスタイリング */
.add-group-content {
    font-family: Arial, sans-serif;
    background-color: rgba(67, 67, 67, 0.907);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* タイトルのスタイリング */
.title {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

/* フォーム全体のスタイリング */
form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
}

/* ラベルのスタイリング */
label {
    display: block;
    margin-bottom: 10px;
    color: #555;
}

/* 入力フィールドのスタイリング */
input[type="text"] {
    width: calc(100% - 22px);
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.del-btn {
    background-color: #f44336;
    color: white;
    padding: 10px 15px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.del-btn:hover {
    background-color: #f44336;
}

.add-btn {
    background-color: #34c2c2;
    color: white;
    padding: 10px 15px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}
.add-btn:hover {
    background-color: #00ffff;
}

input[type="submit"] {
    display: inline-block;
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

/* ボタンホバー時のスタイリング */
input[type="submit"]:hover {
    background-color: #45a049;
}

button.tomypage {
    background-color: #ff0000;
    color: white;
    padding: 10px 15px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

/* ボタンホバー時のスタイリング */
button.tomypage:hover {
    background-color: #d32f2f;
}